<template>
  <div>
    <admin-dashboard v-if="!$auth.user().client" />

    <client-dashboard v-else-if="$auth.user().client" />

    <provider-dashboard v-else-if="0" />
  </div>
</template>

<script>
  export default {
    components: {
      adminDashboard: () => import('@/views/dashboards/Admin.vue'),
      clientDashboard: () => import('@/views/dashboards/Client.vue'),
      providerDashboard: () => import('@/views/dashboards/Provider.vue'),
    },
  }
</script>
